<template>
  <div>
    <a-card title="应付欠款">
      <a-row :gutter="16">
        <a-col :span="20" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="编号" allowClear @search="search" />
        </a-col>

        <a-col :span="4"> <a-button type="primary" @click="onExport">导出</a-button></a-col>
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :scroll="{}" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { arrearsPayableList } from '@/api/finance'
  import {ChangePicker} from "@/utils/excel";

  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        monthFormat : 'YYYY-MM',

        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            fixed: 'left',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '编号',
            dataIndex: 'number',
            fixed: 'left',
          },
          {
            title: '名称',
            dataIndex: 'name',
          },
          {
            title: '初期欠款金额',
            dataIndex: 'initial_arrears_amount',
            width: 120
          },
          {
            title: '欠款金额',
            dataIndex: 'arrears_amount',
            width: 120
          },
          {
            title: '欠款状态',
            dataIndex: 'has_arrears',
            width: 100
          },

          {
            title: '联系人',
            dataIndex: 'contact',
          },
          {
            title: '手机号',
            dataIndex: 'phone',
          },
          {
            title: '邮箱',
            dataIndex: 'email',
          },
          {
            title: '地址',
            dataIndex: 'address',
          },
          {
            title: '银行账户',
            dataIndex: 'bank_account',
          },
          {
            title: '开户行',
            dataIndex: 'bank_name',
          },
          {
            title: '备注',
            dataIndex: 'remark',
          },
          {
            title: '激活状态',
            dataIndex: 'is_active',
          },
        ],
        searchForm: { page: 1, has_arrears: true, page_size: 50 },
        pagination: { current: 1, total: 0, pageSize: 50 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      onChangePicker(a,b){
        if (a == null) {
          this.searchForm.start_date =  a;
          this.searchForm.end_date = a;
          this.search();
          return;
        }
        const {first_day1,last_day1} = ChangePicker(a,b)
        this.searchForm.start_date =  first_day1;
        this.searchForm.end_date = last_day1;
        this.search();
      },
      onExport() {
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []
          tHeader = [ "名称","初期欠款金额","欠款金额", "欠款状态", "联系人", "手机号", "邮箱", "地址", "银行账户", "开户行", "备注", "激活状态"];
          filterVal =["name","initial_arrears_amount","arrears_amount",  "level_display", "contact", "phone", "email", "address", "bank_account", "bank_name", "remark", "is_active"];
          list = this.exportItems
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`应付款`+this.getCurrentDate(),
            autoWidth: true,
            bookType: "xlsx",
          });
        });

      },
      getCurrentDate() {
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
        let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
        let formattedDate = `${year}${month}${day}`; // 拼接年月日
        return formattedDate;
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
            filterVal.map((j) => {
              if (j === "timestamp") {
                return parseTime(v[j]);
              } else {
                return v[j];
              }
            })
        );
      },
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        this.exportItems=[]

        arrearsPayableList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;

          this.items.forEach(item=>{
            const obj=item
            obj.arrears_amount=parseFloat(item.arrears_amount)
            this.exportItems.push(obj)
          })

        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },


      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        this.$router.push({ path: '/finance/flow_detail', query: { item: JSON.stringify(item) } });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>
